import { css, SerializedStyles } from '@emotion/react';
import styled from '@emotion/styled';
import { ChangeEvent } from 'react';

import { colors } from '@/styles/constant';

export enum CheckboxInputSize {
  REGULAR = 'REGULAR',
  MEDIUM = 'MEDIUM',
}
export enum CheckboxInputTheme {
  BROWN,
  NAVY,
}
export interface CheckboxInputProps {
  size?: CheckboxInputSize;
  theme?: CheckboxInputTheme;
  checked: boolean;
  label: React.ReactNode;
  name: string;
  desc?: React.ReactNode;
  marginTop?: string;
  isDisabled?: boolean;
  onChange: (name: string, checked: boolean) => void;
}

const CheckboxInput: React.FC<CheckboxInputProps> = ({
  size = CheckboxInputSize.MEDIUM,
  theme = CheckboxInputTheme.BROWN,
  checked,
  label,
  name,
  desc,
  isDisabled,
  onChange,
  marginTop,
}) => {
  const getSizeStyle = CheckboxInputSizeStyle[size];
  const getColorValue =
    theme === CheckboxInputTheme.BROWN ? colors.BROWN_NEW : colors.NAVY_NEW;
  const id = `${name}CheckBox`;
  const handleChangeChecked = (e: ChangeEvent<HTMLInputElement>) =>
    onChange(e.target.name, e.target.checked);

  return (
    <CheckBoxWrap
      marginTop={marginTop}
      showDesc={!!desc}
      checked={checked}
      color={getColorValue}
      getSizeStyle={getSizeStyle.label}
    >
      <label htmlFor={id}>
        <input
          id={id}
          type='checkbox'
          name={name}
          disabled={isDisabled}
          onChange={handleChangeChecked}
          checked={checked}
        />
        <CheckIcon
          checked={checked}
          color={getColorValue}
          getSizeStyle={getSizeStyle.icon}
        />

        {label && label}
      </label>

      {desc && desc}
    </CheckBoxWrap>
  );
};

export default CheckboxInput;

const CheckboxInputSizeStyle: {
  [index: string]: { label: SerializedStyles; icon: SerializedStyles };
} = {
  [CheckboxInputSize.REGULAR]: {
    label: css`
      padding-left: 22px;
      > label {
        font-size: 12px;
        font-weight: 500;
        line-height: 14px;
      }
    `,
    icon: css`
      height: 14px;
      width: 14px;
      &::after {
        height: 5px;
        margin: 3px 0 0 2.5px;
        width: 7px;
      }
    `,
  },
  [CheckboxInputSize.MEDIUM]: {
    label: css`
      padding-left: 36px;
      > label {
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
      }
    `,
    icon: css`
      height: 24px;
      width: 24px;
      &::after {
        height: 8px;
        margin: 5px 0 0 5px;
        width: 13px;
      }
    `,
  },
};

const CheckBoxWrap = styled.div<{
  marginTop?: string;
  showDesc?: boolean;
  checked: boolean;
  color: string;
  getSizeStyle: SerializedStyles;
}>`
  color: ${colors.DARK_GRAY};
  font-size: 14px;
  line-height: 1.5;
  ${({ marginTop }) => marginTop && `margin-top: ${marginTop}`};
  ${({ getSizeStyle }) => getSizeStyle};
  opacity: ${({ checked }) => (checked ? 1 : 0.75)};
  position: relative;
  text-align: left;

  & + & {
    margin-top: 20px;
  }

  a {
    color: ${({ color }) => color};
    font-weight: bold;
  }

  > label {
    color: #646b79;
    display: block;
    ${({ showDesc }) => showDesc && 'margin-bottom: 8px'};
    opacity: 1;

    &:hover {
      cursor: pointer;
    }

    input {
      display: none;
    }
  }
`;
const CheckIcon = styled.span<{
  checked: boolean;
  color: string;
  getSizeStyle: SerializedStyles;
}>`
  background-color: ${({ checked, color }) => (checked ? color : colors.WHITE)};
  border: 1px solid ${({ color }) => color};
  border-radius: 4px;
  display: block;
  left: 0;
  ${({ getSizeStyle }) => getSizeStyle};
  position: absolute;
  top: 0;

  &::after {
    border-bottom: 2px solid #fff;
    border-left: 2px solid #fff;
    content: '';
    display: ${({ checked }) => (checked ? 'block' : 'none')};
    transform: rotate(-45deg);
  }
`;
